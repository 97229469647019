<script lang="ts" setup>
const { t } = useT();
const { open } = useNlcModals();
const { isMacOS, isIos, isApple } = useDevice();
const appleDevice = computed(() => isMacOS || isIos || isApple);

const handleClick = () => {
	open("LazyOModalPwaApps", { instruction: appleDevice.value, location: "sidebar" });
};
</script>

<template>
	<div class="banner-pwa" @click="handleClick">
		<NuxtImg class="img" src="/nuxt-img/modals/app.png" loading="lazy" format="avif" width="82" height="76" alt="app" />
		<AText class="title" :modifiers="['bold']" as="div">{{ t("sidebar.pwaBanner.text") }}</AText>
		<AButton class="button" variant="primary" size="xs">
			<AText :size="12" :modifiers="['uppercase', 'bold']">
				{{ t("sidebar.pwaBanner.button") }}
			</AText>
		</AButton>
	</div>
</template>

<style scoped lang="scss">
.banner-pwa {
	border-radius: 8px;
	background: var(--gradient-10);
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	padding: 2px 8px;
	.img {
		width: auto;
		height: 48px;
	}
	.title {
		width: 100%;
	}
	.button {
		min-width: 106px;
	}
}
</style>
